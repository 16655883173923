import React, {useState} from "react";
import {Link} from "react-router-dom";
import {PrincipalSidebarData, TeacherSidebarData, UserSidebarData,} from "./sidebarData";
import "./customNavbar.css";
import {HiMenuAlt2} from "react-icons/hi";
import {IconContext} from "react-icons";
import TopNavItems from "./topNavItems";
import {Button} from "react-bootstrap";
import layingLogo from "../../images/vågrätlogo.png";
import {default as Addteacher} from "./icons/add_teacher.svg";
import {default as Vikariat} from "./icons/chalkboard-teacher-solid.svg";
import {PrincipalSettingsData, TeacherSettingsData, UserSettingsData,} from "./settingsData";

const NavbarType = (props) => {
    const {
        navClass,
        fontSize,
        linkClass,
        title,
        showSidebar,
        sidebar,
        page,
        user,
        logoutTeacher,
        applicantCounter,
        tempCount,
        mobile,
        isSmallScreen,
        userType,
        changeType,
    } = props;

    const [breakpoint, setBreakpoint] = useState(null);

    const getLang = (fiTitle, svTitle) => {
        if (user.siteLang === 2) {
            return svTitle;
        } else if (user.siteLang === 1) {
            return fiTitle;
        } else {
            return fiTitle;
        }
    };

    /*  useEffect(() => {
                   console.log(size)
               }, []) */

    const vikariatButtonSwitch = (route) => {
        switch (decodeURIComponent(route)) {
            case "/app/jobs":
                return (
                    <Button href="/jobs/new" variant="newjob-highlighted-2">
                        <p
                            style={{
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                marginBottom: "0",
                            }}
                        >
                            <img alt="" src={Vikariat} className="mr-2 vikariat-svg"/>
                            {user.siteLang === 2 ? "Skapa vikariat" : "Lisää sijaisuus"}
                        </p>
                    </Button>
                );
            default:
                return (
                    <Button href="/jobs/new" variant="newjob">
                        <p
                            style={{
                                fontWeight: "600",
                                marginBottom: "0",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <img alt="" src={Vikariat} className="mr-2 button-vikariat-svg"/>
                            {user.siteLang === 2 ? "Lägg till lärare" : "Lisää sijaisuus"}
                        </p>
                    </Button>
                );
        }
    };

    const teacherButtonSwitch = (route) => {
        switch (decodeURIComponent(route)) {
            case "/app/teachers":
                return (
                    <Button href="/teachers/create" variant="newjob-highlighted-2">
                        <p
                            style={{
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                marginBottom: "0",
                            }}
                        >
                            <img
                                alt=""
                                src={Addteacher}
                                className="mr-2 button-vikariat-svg"
                            />{" "}
                            {user.siteLang === 2 ? "Skapa vikariat" : "Lisää opettaja"}
                        </p>
                    </Button>
                );
            case "/teachers/create":
                return (
                    <Button href="/teachers/create" variant="newjob-highlighted">
                        <p
                            style={{
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                marginBottom: "0",
                            }}
                        >
                            <img
                                alt=""
                                src={Addteacher}
                                className="mr-2 button-vikariat-svg"
                            />{" "}
                            {user.siteLang === 2 ? "Skapa vikariat" : "Lisää opettaja"}
                        </p>
                    </Button>
                );
            default:
                return (
                    <Button href="/teachers/create" variant="newjob">
                        <p
                            style={{
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                marginBottom: "0",
                            }}
                        >
                            <img
                                alt=""
                                src={Addteacher}
                                className="mr-2 button-vikariat-svg"
                            />{" "}
                            {user.siteLang === 2 ? "Skapa vikariat" : "Lisää opettaja"}
                        </p>
                    </Button>
                );
        }
    };

    return (
        <div className="navbar">
            <IconContext.Provider
                value={{style: {fontSize: fontSize, marginBottom: "5px"}}}
            >
                <Link to="#" className={linkClass}>
                    <HiMenuAlt2 onClick={showSidebar}/>
                </Link>
            </IconContext.Provider>
            {title && (
                <h2 className="page-title" style={{marginLeft: "30px"}}>
                    {page}
                </h2>
            )}
            <div className="top-nav-items">
                {mobile ? (
                    <TopNavItems
                        user={user}
                        logoutTeacher={logoutTeacher}
                        page={page}
                        mobile={mobile}
                    />
                ) : (
                    <TopNavItems user={user} logoutTeacher={logoutTeacher} page={page}/>
                )}
            </div>
            {mobile ? (
                sidebar ? (
                    <nav className="nav-menu active">
                        <ul className="nav-menu-items active-menu-items">
                            <li className="nav-logo">
                                <Link to="#" onClick={showSidebar}>
                                    <HiMenuAlt2/>
                                </Link>
                            </li>
                            {userType === "user"
                                ? UserSidebarData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.title
                                                    ? `${item.cName} active-page`
                                                    : item.cName + " " + item.iconStyle
                                            }
                                        >
                                            <Link to={item.path}>
                                                <span className={item.iconStyle}>{item.icon}</span>
                                                <span>{sidebar ? item.title : ""}</span>
                                                {applicantCounter !== null &&
                                                applicantCounter > 0 &&
                                                sidebar ? (
                                                    <span className="app-count text-white">
                              {applicantCounter}
                            </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Link>
                                        </li>
                                    );
                                })
                                : userType === "teacher"
                                    ? TeacherSidebarData.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={
                                                    page === item.title
                                                        ? `${item.cName} active-page`
                                                        : item.cName
                                                }
                                            >
                                                <Link to={item.path}>
                                                    <span className={item.iconStyle}>{item.icon}</span>
                                                    <span>{sidebar ? item.title : ""}</span>
                                                    {applicantCounter !== null &&
                                                    applicantCounter > 0 &&
                                                    sidebar ? (
                                                        <span className="app-count">
                              {applicantCounter}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {tempCount !== null && tempCount > 0 && sidebar ? (
                                                        <span className="app-count-temp">{tempCount}</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Link>
                                            </li>
                                        );
                                    })
                                    : userType === "principal"
                                        ? PrincipalSidebarData.map((item, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={
                                                        page === item.title
                                                            ? `${item.cName} active-page`
                                                            : item.cName
                                                    }
                                                >
                                                    <Link to={item.path}>
                                                        <span className={item.iconStyle}>{item.icon}</span>
                                                        <span>{sidebar ? item.title : ""}</span>
                                                        {item.title === "Anslagstavlan" &&
                                                        applicantCounter !== null &&
                                                        sidebar ? (
                                                            <span className="app-count">
                              {applicantCounter}
                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {item.title === "Vikariat" &&
                                                        tempCount !== null &&
                                                        sidebar ? (
                                                            <span className="app-count-temp">{tempCount}</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Link>
                                                </li>
                                            );
                                        })
                                        : ""}
                        </ul>
                    </nav>
                ) : (
                    ""
                )
            ) : (
                <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
                    <ul
                        className={
                            sidebar ? "nav-menu-items active-menu-items" : "nav-menu-items"
                        }
                    >
                        <li className="nav-logo">
                            <Link to="#" onClick={showSidebar}>
                                {/* {sidebar ? <img className="laying-logo" src={layingLogo} /> : <img src={vlogo} />} */}
                                <div className={sidebar ? "full-container" : "slide-container"}>
                                    <img alt="" src={layingLogo}/>
                                </div>
                            </Link>
                        </li>
                        {userType === "user" ? (
                            <>
                                {UserSidebarData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.sv_title || page === item.fi_title
                                                    ? `nav-text-active`
                                                    : item.cName
                                            }
                                        >
                                            <Link
                                                className={page === item.title ? "activeLink" : ""}
                                                to={item.path}
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        page === item.sv_title || page === item.fi_title
                                                            ? item.active_icon
                                                            : item.icon
                                                    }
                                                    className={item.iconClass}
                                                />
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "600"
                                                                : "",
                                                    }}
                                                    className={`${sidebar ? "text-anim" : ""} ${
                                                        item.title === "Lediga vikariat" ? "move-left" : ""
                                                    }`}
                                                >
                          {sidebar ? getLang(item.fi_title, item.sv_title) : ""}
                        </span>
                                                {item.title === "Lediga vikariat" &&
                                                applicantCounter !== 0 &&
                                                sidebar ? (
                                                    <span
                                                        className={
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "app-count active-count"
                                                                : "app-count"
                                                        }
                                                    >
                            <p className="text-white">{applicantCounter}</p>
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Link>
                                            <span
                                                className={
                                                    page === item.sv_title || page === item.fi_title
                                                        ? "active-page"
                                                        : ""
                                                }
                                            ></span>
                                        </li>
                                    );
                                })}
                                <hr className="nav-separator"></hr>
                                {UserSettingsData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.sv_title || page === item.fi_title
                                                    ? "nav-text-active"
                                                    : item.cName
                                            }
                                        >
                                            <Link
                                                to={{
                                                    pathname: item.path,
                                                    state: {
                                                        compState: item.linkState,
                                                        pageState: {
                                                            fi: item.fi_title,
                                                            sv: item.sv_title,
                                                        },
                                                    },
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        left: item.sos ? "37px" : "",
                                                    }}
                                                    alt=""
                                                    src={
                                                        page === item.sv_title || page === item.fi_title
                                                            ? item.activeIcon
                                                            : item.icon
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "600"
                                                                : "",
                                                    }}
                                                    className={`${sidebar ? "text-anim" : ""}`}
                                                >
                          {sidebar ? getLang(item.fi_title, item.sv_title) : ""}
                        </span>
                                            </Link>
                                            <span
                                                className={
                                                    page === item.sv_title || page === item.fi_title
                                                        ? "active-page"
                                                        : ""
                                                }
                                            ></span>
                                        </li>
                                    );
                                })}
                            </>
                        ) : userType === "teacher" ? (
                            <>
                                {TeacherSidebarData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.sv_title || page === item.fi_title
                                                    ? `nav-text-active`
                                                    : item.cName
                                            }
                                        >
                                            <Link
                                                className={page === item.title ? "activeLink" : ""}
                                                to={item.path}
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        page === item.sv_title || page === item.fi_title
                                                            ? item.active_icon
                                                            : item.icon
                                                    }
                                                    className={item.iconClass}
                                                />
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "600"
                                                                : "",
                                                    }}
                                                    className={`${sidebar ? "text-anim" : ""} ${
                                                        item.title === "Vikariat" ? "move-left" : ""
                                                    }`}
                                                >
                          {sidebar ? getLang(item.fi_title, item.sv_title) : ""}
                        </span>
                                                {/* {
                                                        applicantCounter !== null && applicantCounter > 0 && sidebar ?
                                                            <span className={page === item.sv_title || page === item.fi_title ? "app-count active-count" : "app-count"}>{applicantCounter}</span>
                                                            :
                                                            ''
                                                    }
                                                    {
                                                        tempCount !== null && tempCount > 0 && sidebar ?
                                                            <span className={page === item.sv_title || page === item.fi_title ? "app-count-temp active-count" : "app-count-temp"}>{tempCount}</span>
                                                            :
                                                            ''
                                                    } */}
                                                {item.title === "Anslagstavlan" &&
                                                applicantCounter !== null &&
                                                applicantCounter > 0 &&
                                                sidebar ? (
                                                    <span
                                                        className={
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "app-count active-count"
                                                                : "app-count"
                                                        }
                                                    >
                            <p>{applicantCounter}</p>
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {item.title === "Vikariat" &&
                                                tempCount !== null &&
                                                tempCount > 0 &&
                                                sidebar ? (
                                                    <span
                                                        className={
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "app-count-temp active-count"
                                                                : "app-count-temp"
                                                        }
                                                    >
                            <p>{tempCount}</p>
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Link>
                                            <span
                                                className={
                                                    page === item.sv_title || page === item.fi_title
                                                        ? "active-page"
                                                        : ""
                                                }
                                            ></span>
                                        </li>
                                    );
                                })}
                                <hr className="nav-separator"></hr>
                                {TeacherSettingsData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.sv_title || page === item.fi_title
                                                    ? "nav-text-active"
                                                    : item.cName
                                            }
                                        >
                                            <Link
                                                to={{
                                                    pathname: item.path,
                                                    state: {
                                                        compState: item.linkState,
                                                        pageState: {
                                                            fi: item.fi_title,
                                                            sv: item.sv_title,
                                                        },
                                                    },
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        left: item.sos ? "37px" : "",
                                                    }}
                                                    alt=""
                                                    src={
                                                        page === item.sv_title || page === item.fi_title
                                                            ? item.activeIcon
                                                            : item.icon
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "600"
                                                                : "",
                                                    }}
                                                    className={`${sidebar ? "text-anim" : ""}`}
                                                >
                          {sidebar ? getLang(item.fi_title, item.sv_title) : ""}
                        </span>
                                            </Link>
                                            <span
                                                className={
                                                    page === item.sv_title || page === item.fi_title
                                                        ? "active-page"
                                                        : ""
                                                }
                                            ></span>
                                        </li>
                                    );
                                })}
                            </>
                        ) : userType === "principal" ? (
                            <>
                                {PrincipalSidebarData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.sv_title || page === item.fi_title
                                                    ? `nav-text-active`
                                                    : item.cName
                                            }
                                        >
                                            <Link to={item.path}>
                                                <img
                                                    alt=""
                                                    src={
                                                        page === item.sv_title || page === item.fi_title
                                                            ? item.active_icon
                                                            : item.icon
                                                    }
                                                    className={item.iconClass}
                                                />
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "600"
                                                                : "",
                                                    }}
                                                    className={`${sidebar ? "text-anim" : ""} ${
                                                        item.title === "Vikariat" ? "move-left" : ""
                                                    }`}
                                                >
                          {sidebar ? getLang(item.fi_title, item.sv_title) : ""}
                        </span>
                                                {item.title === "Anslagstavlan" &&
                                                applicantCounter !== null &&
                                                applicantCounter > 0 &&
                                                sidebar ? (
                                                    <span
                                                        className={
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "app-count active-count"
                                                                : "app-count"
                                                        }
                                                    >
                            <p>{applicantCounter}</p>
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {item.title === "Vikariat" &&
                                                tempCount !== null &&
                                                tempCount > 0 &&
                                                sidebar ? (
                                                    <span
                                                        className={
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "app-count-temp active-count"
                                                                : "app-count-temp"
                                                        }
                                                    >
                            <p>{tempCount}</p>
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Link>
                                            <span
                                                className={
                                                    page === item.sv_title || page === item.fi_title
                                                        ? "active-page"
                                                        : ""
                                                }
                                            ></span>
                                        </li>
                                    );
                                })}
                                <hr className="nav-separator"></hr>
                                {PrincipalSettingsData.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                page === item.sv_title || page === item.fi_title
                                                    ? "nav-text-active"
                                                    : item.cName
                                            }
                                        >
                                            <Link
                                                to={{
                                                    pathname: item.path,
                                                    state: {
                                                        compState: item.linkState,
                                                        pageState: {
                                                            fi: item.fi_title,
                                                            sv: item.sv_title,
                                                        },
                                                    },
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        left: item.sos ? "37px" : "",
                                                    }}
                                                    alt=""
                                                    src={
                                                        page === item.sv_title || page === item.fi_title
                                                            ? item.activeIcon
                                                            : item.icon
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        fontWeight:
                                                            page === item.sv_title || page === item.fi_title
                                                                ? "600"
                                                                : "",
                                                    }}
                                                    className={`${sidebar ? "text-anim" : ""}`}
                                                >
                          {sidebar ? getLang(item.fi_title, item.sv_title) : ""}
                        </span>
                                            </Link>
                                            <span
                                                className={
                                                    page === item.sv_title || page === item.fi_title
                                                        ? "active-page"
                                                        : ""
                                                }
                                            ></span>
                                        </li>
                                    );
                                })}
                                {/* TAGS: WIP, NOWORK */}
                                {/* <hr className="nav-separator"></hr>
                                        <div className='change-user-type'>
                                            <span className={`${sidebar ? "text-anim" : ""} change-user-type-text`}>
                                                {user?.typeOfUser !== "user" ? "Byt till vikarie vy" : "Byt till lärar vy"}
                                            </span>
                                            <input checked={() => user?.typeOfUser === "user" ? true : false} onChange={() => {
                                                let type = user?.typeOfUser !== "user" ? 1 : 2
                                                changeType(type)
                                            }} type="checkbox" className="change-user-switch" />
                                        </div> */}
                            </>
                        ) : (
                            ""
                        )}
                        <div id="version-text" className="version">
                            v1.7.2
                        </div>
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default NavbarType;
