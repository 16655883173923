import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Spinner from "../func-components/spinner";
import {
  addChosen,
  deleteTempJob,
  logoutTeacher,
  updateTempJob,
} from "../../actions/authActions";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import "../../styles/body-verticalCenter.css";
import "./managTempJobStyles.css";
import CustomNavbar from "../navigation/customNavbar";
import Subjects from "../../typeaheadData/subjects";
import Subjects_fi from "../../typeaheadData/subjects_fi";
import { Typeahead } from "react-bootstrap-typeahead";
import { default as Editpost } from "../navigation/icons/edit_post.svg";
import PickDate from "../func-components/pickDate";
import PickEndDate from "../func-components/pickEndDate";
import { hours, minutes } from "../tempjob/data/times";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import { default as RemoveIcon } from "../tempjob/icons/delete_purple.svg";
import Backbar from "../mobile/backbar";
import GradeArray from "../../typeaheadData/gradeArray";
import { withAlert } from "react-alert";
import { io } from "socket.io-client";
import ConfirmModal from "../func-components/confirmModal";
import QualificationList from "../../typeaheadData/qualifications";

class ManageTempJob extends Component {
  constructor() {
    super();
    this.state = {
      applicants: [],
      job: {},
      loadingApplicants: true,
      isOpen: false,
      applicant: {},
      jobId: "",
      errors: {},
      gradeTeach: null,
      isEditing: false,
      noApplicants: false,
      startDate: null,
      endDate: null,
      startTime: "",
      reason: "",
      endTime: "",
      subjects: [],
      additionalInfo: "",
      sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
      isLoading: true,
      multipleDays: false,
      language: {},
      mobile: false,
      grades: null,
      jobFiles: null,
      files: [],
      isConfirmOpen: false,
      uploadedFiles: null,
      success: null,
      region: [],
      type: "",
    };
    this.inputFileRef = React.createRef();
  }

  componentDidMount() {
    //getspecific job on load
    if (this.props.auth.teacher.typeOfUser === "user") {
      this.props.history.push("/");
    }
    const data = {
      id: this.props.match.params.jobid,
    };

    this.getLang(this.props.auth.teacher);
    if (this?.props?.location?.state?.fromEditIcon) {
      this.setState({ isEditing: true });
    }

    const handler = () => {
      let match = window.matchMedia("(max-width: 600px)").matches;
      if (match) {
        this.setState({ mobile: true });
      } else {
        this.setState({ mobile: false });
      }
    };
    handler();
    window.addEventListener("resize", handler);

    var socket = io();

    socket.on("updateFilesMT", () => {
      axios
        .post("/api/teachers/getspecific", data)
        .then((res) => {
          console.log("Updates: ", res.data);
          this.setState(
            {
              job: res.data,
              isLoading: false,
              startTime: res.data.startTime,
              endTime: res.data.endTime,
              reason: res.data.reason,
              additionalInfo: res.data.additionalInfo,
              startDate: new Date(res.data.startDate),
              endDate: new Date(res.data.endDate),
              grades: res.data.grades,
              subjects: res.data.subjects,
              jobFiles: res.data.files,
              type: res.data.typeOfTemp?.val,
            },
            () => console.log("tempjob ", this.state.job)
          );
        })
        .catch((err) => console.log(err));
    });

    axios
      .post("/api/teachers/getspecific", data)
      .then((res) => {
        console.log("RES: ", res.data);
        this.setState(
          {
            job: res.data,
            isLoading: false,
            startTime: res.data.startTime,
            endTime: res.data.endTime,
            reason: res.data.reason,
            additionalInfo: res.data.additionalInfo,
            startDate: new Date(res.data.startDate),
            endDate: new Date(res.data.endDate),
            grades: res.data.grades,
            subjects: res.data.subjects,
            jobFiles: res.data.files,
            region: res.data.region,
            type: res.data.typeOfTemp?.val,
          },
          () => {
            //get all applicants for a specific job
            axios
              .post("/api/teachers/showapplicants", {
                region: this.state.region,
              })
              .then((res) => {
                this.setState(
                  { applicants: res.data, loadingApplicants: false },
                  () => {
                    if (
                      this.state.applicants.length < 0 ||
                      typeof this.state.applicants === "undefined"
                    ) {
                      this.setState({ noApplicants: true });
                    }
                  }
                );
              })
              .catch((err) => console.log(err));
          }
        );
      })
      .catch((err) => console.log(err));
  }

  handleDelete = (e) => {
    e.preventDefault();

    const deleteId = {
      id: e.target.id,
    };
    this.props.deleteTempJob(deleteId, this.props.history);
  };

  getLang = (user) => {
    if (user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this.setState({ language: fi });
    }
  };

  handleFileClick = (e) => {
    this.inputFileRef.current.click();
  };

  onFileChange = (e) => {
    /* this.setState({ docs: e.target.files }, 
                                                () => console.log("doc: ", this.state.doc, "\n", "file: ", this.state.file)); */
    const files = e.target.files;
    let temp = this.state.files || [];
    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }
    this.setState({ files: temp }, () =>
      console.log("files: ", this.state.files)
    );
  };

  handleUpdate = () => {
    this.setState({ isEditing: true });
  };

  handleCloseChange = ({ isOpen }) => {
    this.setState({ isOpen: false });
  };

  handleCloseConfirmChange = () => {
    this.setState({ isConfirmOpen: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onStartDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  onEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  //removes already saved files
  handleRemoveFile = (e) => {
    const alert = this.props.alert;
    console.log(e);
    e.preventDefault();

    axios
      .post(`/api/shared/file/delete/${e.target.id}`)
      .then((res) => {
        if (res.data.success) {
          alert.show(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  //removes a file from state
  removeFromFiles = (e) => {
    console.log(e);
    e.preventDefault();

    if (this.state.files.length === 1) {
      this.setState({ files: [] });
    } else {
      const index = e.target.id;
      let temp = this.state.files;
      temp.splice(index, 1);
      this.setState({ files: temp });
    }
  };

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  handleCheckbox = (e) => {
    const { subjects } = this.state;
    let check = e.target.checked;
    let checked_val = e.target.id;
    if (check) {
      subjects.push(checked_val);
    } else {
      var index = subjects.indexOf(checked_val);
      if (index > -1) {
        subjects.splice(index, 1);
      }
    }
  };

  handleFileUpload = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      this.state.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      axios
        .post("/api/shared/upload/files", formData)
        .then((res) => {
          if (res.data.success) {
            resolve(true);
          } else {
            reject("Promise rejected, success false");
          }
          this.setState({
            success: res.data.success,
            uploadedFiles: res.data.files,
          });
        })
        .catch((err) => console.error(err));
    });
  };

  onSubmit = (e) => {
    const { job } = this.state;
    e.preventDefault();

    console.log("grades: ", this.state.grades);

    this.handleFileUpload()
      .then((res) => {
        let fileNameArr = this.state.jobFiles || [];
        if (this.state.uploadedFiles !== null) {
          this.state.uploadedFiles.forEach((file) => {
            fileNameArr.push(file.filename);
          });
        }
        /* console.log("FILE NAME ARR: ", fileNameArr) */
        let tempType = QualificationList.find((e) => e.val === this.state.type);
        const updatedPos = {
          id: this.props.match.params.jobid,
          startDate: this.state.startDate.toDateString(),
          endDate: this.state.endDate
            ? this.state.endDate.toDateString()
            : this.state.startDate.toDateString(),
          startTime: this.state.startTime,
          reason: this.state.reason,
          endTime: this.state.endTime,
          subjects: this.state.subjects ? this.state.subjects : job.subjects,
          additionalInfo: this.state.additionalInfo,
          grades: this.state.grades ? this.state.grades : job?.grades,
          files: fileNameArr,
          typeOfTemp: this.state.type ? tempType : job.type,
        };
        /* console.log("UPDATED POS: ", updatedPos) */
        this.props.updateTempJob(updatedPos);
      })
      .catch((err) => console.log(err));
  };

  /*  handleOpenChange = e => {
                           console.log(e.target.id)
                           for (let i = 0; i < this.state.applicants.length; i++) {
                               if (this.state.applicants[i].email === e.target.id) {
                                   this.setState({ applicant: this.state.applicants[i] })
                               } else {
                                   console.log("Doesnt exist")
                               }
                           }
                           this.setState({ isOpen: true })
                       } */
  handleOpenChange = (user, jobId) => {
    this.setState({ applicant: user, jobId: jobId });
    this.setState({ isOpen: true });
  };

  //Coa = choice of aspirant
  handleCoa = (e) => {
    console.log("chosen: ", e.target.id);
    const data = {
      email: e.target.id,
      id: this.props.match.params.jobid,
    };

    if (window.confirm("Är du säker på ditt val?")) {
      this.props.addChosen(data);
    } else {
      alert("Ok, ingen aspirant vald ännu.");
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  displayDates = (job) => {
    var options = { day: "numeric", month: "numeric" };
    if (
      new Date(job.startDate).toDateString() ===
      new Date(job.endDate).toDateString()
    ) {
      return new Date(job.startDate).toLocaleDateString("fi-FI");
    }
    return (
      new Date(job.startDate).toLocaleDateString("fi-FI", options) +
      "-" +
      new Date(job.endDate).toLocaleDateString("fi-FI")
    );
  };

  typeOfButton = (job, user) => {
    if (job.chosen === user.email) {
      return (
        <Button
          variant="custom"
          block
          id={user.email}
          onClick={this.handleOpenChange(user, job.uniqueId)}
        >
          Vald aspirant
        </Button>
      );
    } else if (typeof job.chosen === "undefined") {
      return (
        <Button
          variant="custom"
          block
          id={user.email}
          onClick={this.handleOpenChange(user, job.uniqueId)}
        >
          Visa
        </Button>
      );
    } else if (job.chosen !== user.email) {
      return (
        <Button variant="custom" block id={user.email} disabled>
          Redan valt någon annan
        </Button>
      );
    }
  };

  handleEdit = (e) => {
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing,
    }));
  };

  handleCheckbox = (e) => {
    const { multipleDays } = this.state;
    let check = e.target.checked;
    if (check) {
      this.setState({ multipleDays: true });
    } else {
      this.setState({ multipleDays: false });
    }
  };

  render() {
    const {
      job,
      applicants,
      isEditing,
      mobile,
      loadingApplicants,
      sidebar,
      isLoading,
      multipleDays,
      language,
      files,
      jobFiles,
      errors,
      gradeTeach,
      isConfirmOpen,
    } = this.state;
    const { teacher } = this.props.auth;
    return (
      <div className="show-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={sidebar}
          showSidebar={this.showSidebar}
          page={language.fi ? "Muokkaa Sijaisuus" : "Redigera Vikariatet"}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        <div className={sidebar ? "viewjob-main push-right" : "viewjob-main"}>
          <Container fluid>
            {isConfirmOpen && (
              <ConfirmModal
                header="Är du säker?"
                content="Vill du verkligen ta bort detta vikariatet?"
                execute={this.handleDelete}
                isConfirmOpen={isConfirmOpen}
                handleCloseConfirmChange={this.handleCloseConfirmChange}
                id={job.uniqueId}
              />
            )}
            {!isLoading ? (
              <Card
                className="mt-3"
                style={{
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                  marginBottom: "6rem",
                }}
              >
                <Backbar
                  title={language.back}
                  padding={true}
                  goback={this.goBack}
                  sticky={mobile}
                  desktop={!mobile}
                />
                {!isEditing ? (
                  <Row>
                    <Col>
                      <Card.Body className="mt-3">
                        <Card.Title className="d-flex">
                          {job.school}
                          <img
                            alt=""
                            src={Editpost}
                            id={job.uniqueId}
                            className="edit-icon ml-auto"
                            onClick={this.handleEdit}
                          />
                          <img
                            alt=""
                            id={job.uniqueId}
                            className="delete-icon ml-5"
                            onClick={() =>
                              this.setState({ isConfirmOpen: true })
                            }
                            src={RemoveIcon}
                          />
                        </Card.Title>
                        <Row className="cReg t-md f-col">
                          <Form.Group controlId="dates" as={Col}>
                            <Form.Label className=" cBold">
                              {language.date}
                            </Form.Label>
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={this.displayDates(job)}
                            />
                          </Form.Group>
                          <Form.Group controlId="dates" as={Col}>
                            <Form.Label className=" cBold">
                              {language.reason}
                            </Form.Label>
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={job.reason}
                            />
                          </Form.Group>
                        </Row>
                        <Row className="cReg t-md f-col">
                          <Form.Group controlId="time" as={Col}>
                            <Form.Label className=" cBold">
                              {language.jobfor}
                            </Form.Label>
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={
                                language.fi
                                  ? job?.typeOfTemp?.name_fi
                                  : job?.typeOfTemp?.name_swe
                              }
                            />
                          </Form.Group>
                          <Form.Group controlId="time" as={Col}>
                            <Form.Label className=" cBold">
                              {language.times}
                            </Form.Label>
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={`${job.startTime} - ${job.endTime}`}
                            />
                          </Form.Group>
                        </Row>
                        <Row className="cReg t-md f-col">
                          <Form.Group controlId="subjects" as={Col}>
                            <Form.Label className=" cBold">
                              {language.subjects}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled
                              className="custom-control"
                              placeholder={job.subjects.join(", ")}
                            />
                          </Form.Group>
                        </Row>
                        <Row className="cReg t-md f-col">
                          <Form.Group controlId="extra" as={Col}>
                            <Form.Label className=" cBold">
                              {language.extra}
                            </Form.Label>
                            <Form.Control
                              disabled
                              as="textarea"
                              className="custom-control"
                              placeholder={job.additionalInfo}
                            />
                          </Form.Group>
                          <Form.Group controlId="grades" as={Col}>
                            <Form.Label className=" cBold">
                              {language.grade}
                            </Form.Label>
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={
                                job?.grades ? job.grades.join(", ") : ""
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="cReg t-md f-col"></Row>
                        <Row className="cReg t-md f-col">
                          <Col>
                            <ListGroup className="mb-3">
                              {jobFiles.length > 0
                                ? jobFiles.map((file, index) => (
                                    <ListGroupItem key={index}>
                                      <img
                                        id={file}
                                        onClick={this.handleRemoveFile}
                                        className="mr-2 pe-cursor"
                                        src={RemoveIcon}
                                        alt=""
                                      />{" "}
                                      {file.substr(32)}
                                    </ListGroupItem>
                                  ))
                                : ""}
                            </ListGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Col>
                  </Row>
                ) : (
                  <Form noValidate onSubmit={this.onSubmit}>
                    <Row className="f-col">
                      <Col>
                        <Card.Body className="mt-3">
                          <Card.Title>
                            <h4>{language.editdropdwn}</h4>
                          </Card.Title>
                          <Form.Row>
                            <Form.Group as={Col} controlId="date">
                              <Form.Label className="lt cBold">
                                {language.date}{" "}
                              </Form.Label>
                              <span className="text-danger lt"></span>
                              <PickDate
                                language={language}
                                startDate={this.state.startDate}
                                handleStartDateChange={this.onStartDateChange}
                              />
                            </Form.Group>
                            <span style={{ marginTop: "2.5rem" }}> - </span>
                            <Form.Group as={Col} controlId="enddate">
                              <Form.Label className="lt cBold">
                                &nbsp;
                              </Form.Label>
                              <PickEndDate
                                language={language}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                handleEndDateChange={this.onEndDateChange}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="startTime">
                              <Form.Label className="lt cBold">
                                {language.begins}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                onChange={this.onChange}
                                value={this.state.startTime}
                              >
                                {hours.map((hour, hr_index) =>
                                  minutes.map((min, index) => (
                                    <option key={index}>
                                      {hour + ":" + min}
                                    </option>
                                  ))
                                )}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="endTime">
                              <Form.Label className="lt cBold">
                                {language.ends}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                onChange={this.onChange}
                                value={this.state.endTime}
                              >
                                {hours.map((hour, hr_index) =>
                                  minutes.map((min, index) => (
                                    <option
                                      disabled={
                                        this.state.startTime >= hour + ":" + min
                                          ? true
                                          : false
                                      }
                                      key={index}
                                    >
                                      {hour + ":" + min}
                                    </option>
                                  ))
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="reason">
                              <Form.Label className="lt cBold">
                                {language.reason}{" "}
                                <small className="text-muted">
                                  {language.onlyforprin}
                                </small>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                onChange={this.onChange}
                                value={this.state.reason}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="type">
                              <Form.Label className="lt cBold">
                                {language.jobfor}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                onChange={this.onChange}
                                value={this.state.type}
                              >
                                {QualificationList.map((quali, index) => (
                                  <option key={index} value={quali.val}>
                                    {language.fi
                                      ? quali.name_fi
                                      : quali.name_swe}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>

                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label className="lt cBold">
                                {language.chosensubjects}
                              </Form.Label>

                              <Typeahead
                                className="subject-picker"
                                id="subject-picker"
                                multiple
                                defaultSelected={this?.state?.subjects}
                                options={
                                  language.sv
                                    ? Subjects.sort()
                                    : Subjects_fi.sort()
                                }
                                placeholder={language.oneormore}
                                onChange={(subjects) => {
                                  this.setState({ subjects });
                                }}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label className="lt cBold">
                                {language.grade}
                              </Form.Label>
                              <Typeahead
                                className="subject-picker"
                                placeholder={language.oneormore_standard}
                                id="grade-picker"
                                selected={this.state.grades.map(String)}
                                multiple
                                options={
                                  language.fi ? GradeArray.fi : GradeArray.sv
                                }
                                onChange={(grades) => {
                                  this.setState({ grades: grades.sort() });
                                }}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Group controlId="additionalInfo">
                            <Form.Label className="lt cBold">
                              {language.extra}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              onChange={this.onChange}
                              value={this.state.additionalInfo}
                            />
                          </Form.Group>
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.File
                                ref={this.inputFileRef}
                                className="text-muted"
                                id="files"
                                type="file"
                                onChange={this.onFileChange}
                                style={{ display: "none" }}
                                multiple
                              />
                              <Button
                                variant="custom-reverse"
                                block
                                onClick={this.handleFileClick}
                              >
                                {language.choosedocs}
                              </Button>
                              <p
                                className="text-muted mt-2 text-center"
                                style={{ fontSize: "1em" }}
                              >
                                {language.docswarning}
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <Row>
                            <ListGroup className="mb-3" as={Col}>
                              {this.state.files.length > 0
                                ? this.state.files.map((file, index) => (
                                    <ListGroupItem key={index}>
                                      <img
                                        id={index}
                                        onClick={this.handleRemoveFile}
                                        className="mr-2 pe-cursor"
                                        src={RemoveIcon}
                                        alt=""
                                      />{" "}
                                      {file.name}
                                      <p
                                        style={{
                                          display:
                                            file.size / 1024 / 1024 > 1
                                              ? "initial"
                                              : "none",
                                        }}
                                        className="text-danger lt"
                                      >
                                        {" "}
                                        *Filen är för stor(max 1mb)
                                      </p>
                                    </ListGroupItem>
                                  ))
                                : ""}
                            </ListGroup>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <Button
                                variant="custom"
                                block
                                size="lg"
                                type="submit"
                              >
                                {language.savechanges}
                              </Button>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <Button
                                variant="customDelete"
                                block
                                size="lg"
                                onClick={() =>
                                  this.setState({ isEditing: false })
                                }
                              >
                                {language.cancel}
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Card>
            ) : (
              <div className="spin-wrapper">
                <Spinner size="8x" spinning={"spinning"} />
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

ManageTempJob.propTypes = {
  updateTempJob: PropTypes.func.isRequired,
  deleteTempJob: PropTypes.func.isRequired,
  logoutTeacher: PropTypes.func.isRequired,
  addChosen: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  deleteTempJob,
  updateTempJob,
  addChosen,
  logoutTeacher,
})(withRouter(withAlert()(ManageTempJob)));
