import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";
import { default as FAQ } from "./icons/faq.svg";
import { default as GDPR } from "./icons/gdpr.svg";
import { default as Notiser } from "./icons/notiser.svg";
import { default as Urgent } from "./icons/setup_grey.svg";

import { default as activeFAQ } from "./icons/faqActive.svg";
import { default as activeGDPR } from "./icons/gdprActive.svg";
import { default as activeNotiser } from "./icons/notiserActive.svg";
import { default as activeUrgent } from "./icons/setup_lila.svg";
import { default as Support } from "./icons/support_grey.svg";
import { default as Support_active } from "./icons/support_lila.svg";

export const TeacherSettingsData = [
  {
    sv_title: sv.sos_standardtext,
    fi_title: fi.sos_standardtext,
    path: "/settings",
    cName: "nav-text",
    linkState: 7,
    icon: Urgent,
    activeIcon: activeUrgent,
  },
  {
    sv_title: sv.notifications,
    fi_title: fi.notifications,
    path: "/settings",
    cName: "nav-text",
    linkState: 1,
    icon: Notiser,
    activeIcon: activeNotiser,
  },
  {
    sv_title: "FAQ",
    fi_title: "FAQ",
    path: "/settings",
    cName: "nav-text",
    linkState: 5,
    icon: FAQ,
    activeIcon: activeFAQ,
  },
  /* {
                    sv_title: sv.support,
                    fi_title: fi.support,
                    path: '/settings',
                    cName: 'nav-text',
                    linkState: 5
                }, */
  {
    sv_title: sv.security,
    fi_title: fi.security,
    path: "/settings",
    cName: "nav-text",
    linkState: 4,
    icon: GDPR,
    activeIcon: activeGDPR,
  },
  {
    sv_title: "Support",
    fi_title: "Tuki",
    path: "/settings",
    cName: "nav-text",
    linkState: 6,
    icon: Support,
    activeIcon: Support_active,
  },
];

export const UserSettingsData = [
  {
    sv_title: sv.notifications,
    fi_title: fi.notifications,
    path: "/settings",
    cName: "nav-text",
    linkState: 1,
    icon: Notiser,
    activeIcon: activeNotiser,
  },
  {
    sv_title: "FAQ",
    fi_title: "FAQ",
    path: "/settings",
    cName: "nav-text",
    linkState: 5,
    icon: FAQ,
    activeIcon: activeFAQ,
  },
  /* {
                    sv_title: sv.support,
                    fi_title: fi.support,
                    path: '/settings',
                    cName: 'nav-text',
                    linkState: 5
                }, */
  {
    sv_title: sv.security,
    fi_title: fi.security,
    path: "/settings",
    cName: "nav-text",
    linkState: 4,
    icon: GDPR,
    activeIcon: activeGDPR,
  },
  {
    sv_title: "Support",
    fi_title: "Tuki",
    path: "/settings",
    cName: "nav-text",
    linkState: 6,
    icon: Support,
    activeIcon: Support_active,
  },
];

export const PrincipalSettingsData = [
  {
    sv_title: sv.notifications,
    fi_title: fi.notifications,
    path: "/settings",
    cName: "nav-text",
    linkState: 1,
    icon: Notiser,
    activeIcon: activeNotiser,
  },
  {
    sv_title: sv.settings,
    fi_title: fi.settings,
    path: "/principal/settings",
    cName: "nav-text",
    icon: Urgent,
    activeIcon: activeUrgent,
  },
  {
    sv_title: "FAQ",
    fi_title: "FAQ",
    path: "/settings",
    cName: "nav-text",
    linkState: 5,
    icon: FAQ,
    activeIcon: activeFAQ,
  },
  /* {
                    sv_title: sv.support,
                    fi_title: fi.support,
                    path: '/settings',
                    cName: 'nav-text',
                    linkState: 5
                }, */
  {
    sv_title: sv.security,
    fi_title: fi.security,
    path: "/settings",
    cName: "nav-text",
    linkState: 4,
    icon: GDPR,
    activeIcon: activeGDPR,
  },
  {
    sv_title: "Support",
    fi_title: "Tuki",
    path: "/settings",
    cName: "nav-text",
    linkState: 6,
    icon: Support,
    activeIcon: Support_active,
  },
];
