import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

import {GET_ERRORS, IS_EDITING, SET_CURRENT_TEACHER, SET_USED_CODE, TEACHER_LOADING, VALID_RESET_TOKEN,} from "./types";

/* const config = {
    headers: {
        Authroization: 'Bearer' + authToken,
    }
} */

//register teacher
export const registerTeacher = (teacherData, history) => (dispatch) => {
    const promise = axios
        .post("/api/teachers/register", teacherData)
        .then((res) => {
            return res;
        }) //redirection to login, email confirmation?
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
    const dataPromise = promise.then((res) => res.data);
    return dataPromise;
};
//register user(aspirant)
export const registerUser = (userData, history) => (dispatch) => {
    axios
        .post("/api/users/register-user", userData)
        .then((res) =>
            history.push({
                pathname: "/login",
                state: {newReg: true},
            })
        )
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
export const sendNewConfirmationEmail = async (data) => {
    try {
        const res = await axios.post("/api/users/confirm-email", data);
        return res.data;
    } catch (e) {
        console.error(e);
    }
};
//register principal
export const registerPrincipal = (principalData, history) => (dispatch) => {
    axios
        .post("/api/principals/register", principalData)
        .then((res) => history.push("/login"))
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//get security code from teacher
export const checkSecurityCode = (codeData, history) => (dispatch) => {
    axios
        .post("/api/teachers/securitycode", codeData)
        .then((res) => {
            dispatch(setUsedCode(true));
            if (res.error) {
                alert(res.error);
            }
            history.push({
                pathname: "/register/teacher",
                state: {school: res.data.school},
            });
        })
        .catch((err) => {
            console.error(err);
            alert(err.response.data.error);
        });
};

//endpoint for adding new teacher (principal route)
export const createTeacher = (data) => (dispatch) => {
    axios
        .post("/api/principals/teacher/add", data)
        .then((res) => {
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

export const sendInformationTeacher = (data) => (dispatch) => {
    axios
        .post("/api/principals/send/information", data)
        .then((res) => {
        })
        .catch((err) => console.error(err));
};

//endpoint = principals/create/code
export const createNewCode = (data) => (dispatch) => {
    axios
        .post("/api/principals/create/code", data)
        .then((res) => {
            alert("Ny kod skapad!");
            window.location.reload();
        })
        .catch((err) => console.error(err));
};
//user/teacher login client side endpoint

export const login = (loginData, history) => (dispatch) => {
    axios
        .post("/api/shared/login", loginData)
        .then((res) => {
            //save and set token to local storage
            const {token} = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentTeacher(decoded));

            //set email as cookie, saving email for next login
            const obj = JSON.parse(res.config.data);
            Cookies.set("_email", JSON.stringify(obj.email));

            //set last login time
            let data = {email: loginData.email};
            axios
                .post("/api/shared/lastlogin", data)
                .then((res) => {
                    history.push("/jobs/overview");
                })
                .catch((err) => console.error(err));
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//saving changes to profile
export const saveProfile = (profileData, history) => (dispatch) => {
    axios
        .post("/api/shared/saveprofile", profileData)
        .then((res) => {
            const {token} = res.data;
            console.log(token);
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            console.log(decoded);
            dispatch(setCurrentTeacher(decoded));
            window.location.reload();
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//forgot password route
export const passwordReset = (email) => (dispatch) => {
    axios
        .post("/api/shared/forgot", email)
        .then((res) => {
            dispatch(setValidToken(true));
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//reset the forgot password route
export const newPassword = (data, history) => (dispatch) => {
    axios
        .post("/api/shared/reset/:token", data)
        .then((res) => history.push("/login"))
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//email confirmation
export const confirmEmail = (data, history) => (dispatch) => {
    axios
        .post("/api/shared/confirm/:id", data)
        .then((res) => {
            alert(res.data.msg);
            history.push("/login");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//upload image to server
export const uploadImage = (data) => (dispatch) => {
    axios.post("/api/shared/image", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};
//get image
export const getImage = (data) => {
    axios
        .post("/api/shared/image/:filename", data)
        .catch((err) => console.error(err));
};
//save image filename to user schema
export const savePic = (data) => (dispatch) => {
    axios
        .post("/api/shared/add/profilepic", data)
        .then((res) => {
            const {token} = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentTeacher(decoded));
            window.location.reload();
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//add a temporary position(teachers only)
export const addPosition = (data, history) => (dispatch) => {
    axios
        .post("/api/teachers/addtemp", data)
        .then((res) => history.push("/jobs/overview"))
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
export const sosPos = (data) => (dispatch) => {
    const promise = axios
        .post("/api/principals/sos", data)
        .then((res) => res)
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });

    return promise.then((res) => res.data);
};

//delete temp job path
export const deleteTempJob = (data, history) => (dispatch) => {
    axios
        .post("/api/teachers/deletetemp", data)
        .then((res) => history.push("/jobs"))
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};
//path if delete happens in /jobs
export const deleteJobFromDropdown = (data) => (dispatch) => {
    axios
        .post("/api/teachers/deletetemp", data)
        .then((res) => window.location.reload())
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//update temp job
export const updateTempJob = (data) => (dispatch) => {
    axios
        .post("/api/teachers/updatetemp", data)
        .then((res) => window.location.reload())
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//update files for job
export const updateJobFiles = (data) => (dispatch) => {
    axios
        .post("/api/teachers/update/job/files", data)
        .then((res) => window.location.reload())
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//increment view count for temp job
export const incViewCount = (data, history) => (dispatch) => {
    axios.post("/api/users/temp/increment", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//call add applicant api route to add user to applicants
export const addApplicant = (data, history) => (dispatch) => {
    axios
        .post("/api/users/addapplicant", data)
        .then((res) => {
            history.push("/jobs/personal");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//remove application from temp job
export const removeApplicant = (data, history) => (dispatch) => {
    axios
        .post("/api/users/removeapplicant", data)
        .then((res) => {
            /* window.location.reload(); */
            history.push("/jobs/all");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//remove chosen
export const removeChosen = (data, history) => (dispatch) => {
    axios
        .post("/api/teachers/remove/chosen", data)
        .then((res) => {
            history.push("/jobs/overview");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//Choose person who got the job
export const addChosen = (data, history) => (dispatch) => {
    const promise = axios
        .post("/api/teachers/addchosen", data)
        .then((res) => res)
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            return err.response.data;
        });
    return promise
        .then((res) => {
            return res?.data ?? res;
        })
        .catch((e) => {
            console.error(e);
            dispatch({
                type: GET_ERRORS,
                payload: e,
            });
            return e;
        });
};

//activate a job (principals only)
export const activateJob = (data) => (dispatch) => {
    axios
        .post("/api/principals/activate", data)
        .then((res) => {
            alert("Jobbet är nu aktiverat!");
            window.location.reload();
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

export const activateTeacher = (data) => (dispatch) => {
    axios
        .post("/api/principals/activate/teacher", data)
        .then((res) => {
            /* window.location.reload() */
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

export const deactivateTeacher = (data) => (dispatch) => {
    axios
        .post("/api/principals/deactivate/teacher", data)
        .then((res) => {
            /* window.location.reload() */
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

export const activateAllTeachers = (data) => (dispatch) => {
    axios.post("/api/principals/activate/all", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

export const deactivateAllTeachers = (data) => (dispatch) => {
    axios.post("/api/principals/deactivate/all", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//super admin creation of school
export const createSchool = (data) => (dispatch) => {
    axios
        .post("/api/admins/school/new", data)
        .then((res) => {
            alert("New school added");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//super admin creation of principal
export const createPrincipal = (data) => (dispatch) => {
    axios
        .post("/api/admins/principal/new", data)
        .then((res) => {
            alert("New principal added");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//save settings for school
export const saveSchoolSettings = (data) => (dispatch) => {
    const promise = axios
        .post("/api/principals/settings/save", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
    const dataPromise = promise.then((res) => res.data);
    return dataPromise;
};

//delete account for user
export const deleteUserAccount = (data) => (dispatch) => {
    axios.post("/api/users/delete/account", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//delete a file from tempjob
export const deleteFile = (filename) => (dispatch) => {
    axios
        .post(`/api/shared/file/delete/${filename}`)
        .then((res) => {
            alert(res.data.message);
            window.location.reload();
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//add school to user blocked list
export const blockSchool = (data) => (dispatch) => {
    axios.post("/api/users/school/block", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//add job to inactive status
export const inactivateJob = (data) => (dispatch) => {
    axios.post("/api/users/job/inactive", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//reactive job that is inactive
export const reactivateJob = (data) => (dispatch) => {
    axios.post("/api/users/job/reactivate", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//delete/hide job for user
export const hideJobForUser = (data) => (dispatch) => {
    axios.post("/api/users/job/delete", data).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//restores a deleted/hidden job for user
export const restoreJob = (data, history) => (dispatch) => {
    axios
        .post("/api/users/job/restore", data)
        .then((res) => {
            history.push("/jobs/all");
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//sendPush status update route(true/false)
export const updatePushStatus = (data) => (dispatch) => {
    axios
        .post("/api/shared/settings/sendpush", data)
        .then((res) => {
            alert(res.data.msg);
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
};

//deletes a teacher
export const deleteTeacher = (email) => (dispatch) => {
    axios.delete(`/api/principals/remove/${email}`).catch((err) => {
        console.error(err);
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    });
};

//GET: has user edited profile?
export const hasEditedProfile = (data) => (dispatch) => {
    const promise = axios
        .post("/api/users/edited/profile", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
    const dataPromise = promise.then((res) => res.data);
    return dataPromise;
};

//post: change user type TAGS: WIP, NOWORK
export const changeUserType = (data) => (dispatch) => {
    const promise = axios
        .post("/api/shared/change/type", data)
        .then((res) => {
            const {token} = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentTeacher(decoded));
            window.location.reload();
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        });
    const dataPromise = promise.then((res) => res.data);
    return dataPromise;
};

export const getAdminmessages = (data) => (dispatch) => {
    const promise = axios
        .post("/api/users/vikaaria", data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.error(err));

    return promise.then((res) => res.data);
};

export const getSchoolCodes = (data) => (dispatch) => {
    const promise = axios
        .post("/api/principals/codes", data)
        .then((res) => res)
        .catch((err) => console.error(err));
    return promise.then((res) => res.data);
};

export const rememberUser = (data) => (dispatch) => {
    const promise = axios
        .post("/api/shared/remember", data)
        .then((res) => {
            const {token} = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentTeacher(decoded));
            return res;
        })
        .catch((err) => console.error(err));

    return promise.then((res) => res.data);
};

export const setDefaultDescription = (data) => (dispatch) => {
    const promise = axios
        .post("/api/shared/default-description", data)
        .then((res) => {
            const {token} = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentTeacher(decoded));
            return res;
        })
        .catch((err) => console.error(err));

    return promise.then((res) => res.data);
};

//redux store functions
export const setCurrentTeacher = (decoded) => {
    return {
        type: SET_CURRENT_TEACHER,
        payload: decoded,
    };
};

export const setUsedCode = () => {
    return {
        type: SET_USED_CODE,
    };
};

export const setTeacherLoading = () => {
    return {
        type: TEACHER_LOADING,
    };
};

export const setValidToken = () => {
    return {
        type: VALID_RESET_TOKEN,
    };
};

export const setIsEditing = () => {
    return {
        type: IS_EDITING,
    };
};

export const logoutTeacher = () => (dispatch) => {
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    dispatch(setCurrentTeacher({}));
};
