import React, { Component } from 'react';
import { logoutTeacher } from '../../actions/authActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Figure, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faUserCircle, 
    faHome, 
    faChalkboardTeacher, 
    faCalendarCheck, 
    faSignOutAlt,
    faUserFriends, 
    faThLarge, 
    faGraduationCap, 
    faCode, 
    faCog, 
    faChartBar, 
    faCalendar, 
    faBars 
} from '@fortawesome/free-solid-svg-icons';

import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, withRouter } from 'react-router-dom';
import '../../styles/navbar.css';
import logo from '../../images/logo.png';
import axios from 'axios';


class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            isTeacher: false,
            isUser: false,
            isPrincipal: false,
            applicantCounter: null,
            tempCounter: null,
            collapsed: true,
            toggled: false,
        }
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.setState({
            isUser: false,
            isTeacher: false,
            isPrincipal: false
        })
        this.props.logoutTeacher();
    };

    isAuth_isTeacher = () => {
        if (this.props.auth.isAuthenticated === true && this.props.auth.teacher.typeOfUser === "teacher") {
            this.setState({ isTeacher: true })
        } else {
            this.setState({ isTeacher: false })
        }
    }
    isAuth_isUser = () => {
        if (this.props.auth.isAuthenticated === true && this.props.auth.teacher.typeOfUser === "user") {
            this.setState({ isUser: true })
        } else {
            this.setState({ isUser: false })
        }
    }
    isAuth_isPrincipal = () => {
        if (this.props.auth.isAuthenticated === true && this.props.auth.teacher.typeOfUser === "principal") {
            this.setState({ isPrincipal: true }, () => {
                const data = {
                    email: this.props.auth.teacher.email
                }
                axios.post("/api/principals/temps", data)
                    .then(res => {
                        const {jobs} = res.data;
                        let appliedCount = 0;
                        let jobCount = 0;
                        jobs.forEach(job => {
                            appliedCount += job.applicants.length
                            jobCount += 1
                        });
                        this.setState({applicantCounter: appliedCount, tempCounter: jobCount})
                    }).catch(err => console.log(err))
            })
        } else {
            this.setState({ isPrincipal: false })
        }
    }

    componentDidMount() {
        this.isAuth_isTeacher()
        this.isAuth_isUser()
        this.isAuth_isPrincipal()

    }

    handleMenu = () => {
        if (this.state.collapsed && !this.state.toggled) {
            this.setState({ collapsed: false })
        } else if(!this.state.collapsed) {
            this.setState({ collapsed: true })
        } else if(!this.state.collapsed && this.state.toggled){
            this.setState({collapsed: true})
        }
    }
    handleToggle = () => {
        this.setState({toggled: true})
    }

    render() {
        const { isTeacher, isUser, isPrincipal, collapsed, applicantCounter, toggled, tempCounter } = this.state
        
        return (
            <div  className="sidebar">
                {isTeacher && (
                    <ProSidebar
                        collapsed={collapsed}
                        toggled={toggled}
                        breakPoint="sm"
                        className="color-white"
                    >
                        <SidebarHeader className="bg-nav">
                            <Menu>
                                {collapsed ? (
                                    <MenuItem icon={<FontAwesomeIcon size="lg" icon={faBars} />} onClick={this.handleMenu} />
                                ) :
                                    <MenuItem onClick={this.handleMenu}>
                                        <Row className="justify-content-md-center">
                                            <Figure>
                                                <Figure.Image
                                                    width={100}
                                                    height={40}
                                                    src={logo}
                                                />
                                            </Figure>
                                        </Row>
                                    </MenuItem>
                                }
                            </Menu>
                        </SidebarHeader>
                        <SidebarContent className="bg-nav">
                            <Menu >
                            <MenuItem className={this.props.page === "anslagstavlan" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faThLarge} />}>
                                    Anslagstavlan 
                                    <Link to="/jobs/overview" />
                                    <div className="appCount"><span className="countText">{applicantCounter}</span></div>
                                </MenuItem>
                                <MenuItem className={this.props.page === "vikariat" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faGraduationCap} />}>
                                    Vikariat 
                                    <Link to="/jobs" />
                                    <div className="appCount-vik"><span className="countText">{tempCounter}</span></div>
                                </MenuItem>
                                <MenuItem className={this.props.page === "kalender" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faCalendar} />}>
                                    Kalender 
                                    <Link to="/jobs/overview" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "inställningar" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faCog} />}>
                                    Inställningar 
                                    <Link to="/jobs/overview" />
                                </MenuItem>
                                <SubMenu title="Dev" icon={<FontAwesomeIcon icon={faCode} />}>
                                    <MenuItem>Hem <Link to="/" /></MenuItem>
                                    <MenuItem>Profil <Link to="/profile" /></MenuItem>
                                    <MenuItem>Skapa vikariat <Link to="/jobs/new" /></MenuItem>
                                    <MenuItem>Skapa kod <Link to="/code/creation" /></MenuItem>
                                    <MenuItem>Skapa skola <Link to="/school/add" /></MenuItem>
                                </SubMenu>
                            </Menu>
                        </SidebarContent>
                        <SidebarFooter className="bg-nav">
                            <Menu>
                                <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />}><Button variant="custom" block onClick={this.onLogoutClick} >Logga ut</Button></MenuItem>
                            </Menu>
                        </SidebarFooter>
                    </ProSidebar>
                )}
                {isUser && (
                    <ProSidebar 
                        collapsed={collapsed}
                        toggled="true"
                        className="color-white"
                    >
                        <SidebarHeader className="bg-nav">
                            <Menu>
                                {collapsed ? (
                                    <MenuItem icon={<FontAwesomeIcon size="lg" icon={faBars} />} onClick={this.handleMenu} />
                                ) :
                                    <MenuItem onClick={this.handleMenu}>
                                        <Row className="justify-content-md-center">
                                            <Figure>
                                                <Figure.Image
                                                    width={100}
                                                    height={40}
                                                    src={logo}
                                                />
                                            </Figure>
                                        </Row>
                                    </MenuItem>
                                }
                            </Menu>
                        </SidebarHeader>
                        <SidebarContent className="bg-nav">
                            <Menu>
                                <MenuItem className={this.props.page === "home" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faHome} />}>
                                    Hem 
                                    <Link to="/" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "profile" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faUserCircle} />}>
                                    Profil 
                                    <Link to="/profile" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "vikariat" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faChalkboardTeacher} />}>
                                    Vikariat 
                                    <Link to="/jobs/all" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "egnavikariat" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faCalendarCheck} />}>
                                    Dina anmälningar 
                                    <Link to="/jobs/personal" />
                                </MenuItem>
                            </Menu>
                        </SidebarContent>
                        <SidebarFooter className="bg-nav">
                            <Menu>
                                <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />}><Button variant="custom" block onClick={this.onLogoutClick} >Logga ut</Button></MenuItem>
                            </Menu>
                        </SidebarFooter>
                    </ProSidebar>
                )}
                {isPrincipal && (
                    <ProSidebar
                        collapsed={collapsed}
                        className="color-white"
                        breakPoint="sm"
                        toggled={toggled}
                    >
                        <SidebarHeader className="bg-nav">
                            
                            <Menu>
                                {collapsed ? (
                                    <MenuItem icon={<FontAwesomeIcon size="lg" icon={faBars} />} onClick={this.handleMenu} />
                                ) :
                                    <MenuItem onClick={this.handleMenu}>
                                        <Row className="justify-content-md-center">
                                            <Figure>
                                                <Figure.Image
                                                    width={100}
                                                    height={40}
                                                    src={logo}
                                                />
                                            </Figure>
                                        </Row>
                                    </MenuItem>
                                }
                            </Menu>
                        </SidebarHeader>
                        <SidebarContent className="bg-nav">
                            <Menu>
                                <MenuItem className={this.props.page === "anslagstavlan" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faThLarge} />}>
                                    Anslagstavlan 
                                    <Link to="/jobs/overview" />
                                    <div className="appCount"><span className="countText">{applicantCounter}</span></div>
                                </MenuItem>
                                <MenuItem className={this.props.page === "vikariat" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faGraduationCap} />}>
                                    Vikariat 
                                    <Link to="/jobs" />
                                    <div className="appCount-vik"><span className="countText">{tempCounter}</span></div>
                                </MenuItem>
                                <MenuItem className={this.props.page === "kalender" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faCalendar} />}>
                                    Kalender 
                                    <Link to="/jobs/overview" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "lärare" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faUserFriends} />}>
                                    Lärare 
                                    <Link to="/teachers" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "rapporter" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faChartBar} />}>
                                    Rapporter 
                                    <Link to="/jobs/overview" />
                                </MenuItem>
                                <MenuItem className={this.props.page === "inställningar" ? "active-custom" : ""} icon={<FontAwesomeIcon size="lg" icon={faCog} />}>
                                    Inställningar 
                                    <Link to="/principal/settings" />
                                </MenuItem>
                                <SubMenu title="Dev" icon={<FontAwesomeIcon icon={faCode} />}>
                                    <MenuItem>Hem <Link to="/" /></MenuItem>
                                    <MenuItem>Profil <Link to="/profile" /></MenuItem>
                                    <MenuItem>Skapa vikariat <Link to="/jobs/new" /></MenuItem>
                                    <MenuItem>Skapa kod <Link to="/code/creation" /></MenuItem>
                                    <MenuItem>Skapa skola <Link to="/school/add" /></MenuItem>
                                </SubMenu>
                            </Menu>
                        </SidebarContent>
                        <SidebarFooter className="bg-nav">
                            <Menu>
                                <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />}><Button variant="custom" block onClick={this.onLogoutClick} >Logga ut</Button></MenuItem>
                            </Menu>
                        </SidebarFooter>
                    </ProSidebar>
                )}
            </div>
        )
    }
}
Navbar.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutTeacher }
)(withRouter(Navbar));